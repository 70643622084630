<template>
    <section>
      <va-breadcrumbs>
        <va-breadcrumbs-item label="Educação" to="/admin/educacao/escolas" />
        <va-breadcrumbs-item>
          <span style="font-style: italic; color: #0785d3"
            >Planejamento Pedagógico</span
          >
        </va-breadcrumbs-item>
      </va-breadcrumbs>

      <h2 class="titulo-links-escolha">Planejamento Pedagógico</h2>

      <div class="form-group col-md-6">
        <label for="sel1" style="padding-top:15px">Selecione uma disciplina para exibir o seu planejamento pedagógico!</label>
        <select class="form-control" v-model="idDaDisciplina" @change="buscarPlanejamentoDaDisciplina(idDaDisciplina)">
            <option value="0" >Selecione uma disciplina!</option>
            <option v-for="n in disciplinaALL" :key="n" :value="n.id" >{{n.nome}}</option>
        </select>
      </div>
      <div class="row">
        <div class="col-md-10"></div>
        <div class="col-md-2"> <va-button class="mr-2 mb-2" size="small" color="success" @click="registar()" style="float:right;"> {{ $t('Registrar') }}</va-button></div>
      </div>
      <va-input
        class="flex mb-2 md6"
        placeholder="Filtrar..."
        v-model="filter"
      />
      <va-data-table  striped="true" hoverable="true" animated="true" 
        
        :items="items" 
        :columns="columns"
        :per-page="perPage"
        :filter="filter"
        :current-page="currentPage" > 

        <template #cell(id)="{ source: id }">
          <va-button class="mr-2 mb-2" size="small" @click="editarPlanejamento(id)"> {{ $t('Editar') }}</va-button>
          <va-button class="mr-2 mb-2" size="small" @click="deletarPlanejamento(id)"> {{ $t('Deletar') }}</va-button>
        </template>

        <template #bodyAppend>
          <tr><td colspan="8" class="table-example--pagination">
            <va-pagination
              v-model="currentPage"
              input
              :pages="pages"
            />
          </td></tr>
        </template>
      </va-data-table> 
    </section>  
</template>

<script>
import { defineComponent } from 'vue'
import { PlanejamentoPedagogico } from "@/class/planejamentopedagogico";
import { SegmentoEscolar } from "@/class/segmentoEscolar";
import { SeriesEscolar } from "@/class/serie";
import { DisciplinaEscolar } from "@/class/disciplina";

export default defineComponent({
  props:{
    etapa_id:{},
    periodo_id:{},
    turma_id:{},
  },
  data () {
    const columns = [
      {key: 'segmento', label:'Etapa', sortable: true },
      {key: 'serie', label:'Período', sortable: true },
      {key: 'disciplina', label:'Disciplina', sortable: true },
      {key: 'ano', label:'Ano', sortable: true },
      {key: 'mes', label:'Mês', sortable: true },
      {key: 'dia', label:'Dia', sortable: true },
      {key: 'conteudo', label:'Conteúdo'},
      { key: 'id', label:'Ação'},

    ]
    return {
      filter: '',
      items: [],
      idDaDisciplina:0,
      disciplinaALL: [],
      columns,
      perPage: 5,
      currentPage: 1,
    }
  },
  computed: {
    pages () {
      return (this.perPage && this.perPage !== '0')
          ? Math.ceil(this.items.length / this.perPage)
          : this.items.length
    },
  },
  methods:{
      registar(){
        this.$router.push({ name: 'planejamentoPedaCad'});
      },
      editarPlanejamento(id) {
        this.$router.push({ name: "planejamentoPedaEdit", params: { id: id } });
      },
      async deletarPlanejamento(id) {
       const data = await PlanejamentoPedagogico.remover(id);

      },
      async listarTodasDisciplinas() {
        try {
         // let data = await DisciplinaEscolar.obtemTodos();
          let data = await DisciplinaEscolar.obtemTodosSemId1Turma(this.turma_id);
          for (const el of data.data) {
            if(el.na_turma == 1){
              let novo = {
                id: el.id,
                nome: el.nome,
                status: el.status
              }
             this.disciplinaALL.push(novo);
            }
          }
          this.disciplinaALL.push({
            id:1,
            nome:"Todas as disciplinas na turma",
          });
        } catch (e) {}
      },
      async buscarPlanejamentoDaDisciplina(disciplina_id) {
        //Para poder exibir tudo
        if (disciplina_id == 1) {
          this.listarTudo();
        }
        else {
          let payload = {
            segmento_id: this.etapa_id,
            serie_id: this.periodo_id,
            disciplina_id: disciplina_id,
          };
          let data = await PlanejamentoPedagogico.buscarPlanejamentoConfig(payload);
          if (data.data.length == 0) {
            this.$vaToast.init({
              message: "A disciplina selecionada não possui planejamento pedagógico!",
              iconClass: 'fa-star-o',
              position: 'top-right',
              duration: 3500,
              fullWidth: false,
              color: 'warning' 
            })
            this.items = [];
            return false;
          }
          else {
            this.items = [];
            for (const el of data.data) {
              let novo = {
                id_: el.id,
                id: el.id,
                segmento_id:el.segmento_id,
                segmento: el.segmento,
                serie_id: el.serie_id,
                serie: el.serie,
                disciplina_id: el.disciplina_id,
                disciplina: el.disciplina,
                ano: el.ano,
                mes: el.mes,
                dia: el.dia,
                conteudo: el.conteudo
              };
              this.items.push(novo);
            };
          }
        }
      },
      async listarTudo() {
        try {
            let payload = {
              segmento_id: this.etapa_id,
              serie_id: this.periodo_id,
            };

            let data = await PlanejamentoPedagogico.buscarPlanejamentoConfig(payload);
            this.items = [];
            for (const el of data.data) {
              let novo = {
                id_: el.id,
                id: el.id,
                segmento_id:el.segmento_id,
                segmento: el.segmento,
                serie_id: el.serie_id,
                serie: el.serie,
                disciplina_id: el.disciplina_id,
                disciplina: el.disciplina,
                ano: el.ano,
                mes: el.mes,
                dia: el.dia,
                conteudo: el.conteudo
              };
              this.items.push(novo);
            };
        } catch (e) { 
        }
      }, 
  },

  async beforeMount() {
    if (this.etapa_id == null || this.periodo_id == null || this.turma_id == null) {
      this.$router.push({name: 'turma_configurar'});
    }
    else {
      await this.listarTudo();
      await this.listarTodasDisciplinas();
    }
  }
  
})

</script>

<style lang="scss" scoped>
  .table-example--pagination {
    padding-top: 20px;
    text-align: center;
    text-align: -webkit-center;
  }
</style>

<style>
  .semana{
    background-color: rgb(239 239 239);
    text-align: center;
  }
  .font-semana{
    font-size: 10px;
  }
  .dias{
    text-align: center;
  }
  .dia-marcado{
    background-color: #4182c5 !important;
    color: #fff;
  }
</style>

